.App {
  text-align: center;
}

.App-logo {
  max-width: 125px;
  max-height: 125px;
}


.App-logo-icon {
  max-width: 55px;
  max-height: 55px;
}

.ant-btn-primary {
  background-color: #1677ff;
} 

.ant-modal-footer{
  @apply border-t w-full py-2
}

/* .filepond--credits{
  @apply hidden
} */

@media (prefers-reduced-motion: no-preference) {

}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.uppy-Dashboard-AddFiles-info{
  display:hidden!important 
}
.center-div{
 @apply flex w-screen h-screen items-start justify-start flex-row whitespace-nowrap
}
.filterSorting :where(
  .ant-select-selection-item,
  .ant-select-focused
){
  @apply text-gray-400
}
.fw-primary-btn{
  @apply text-white right-14 mt-1 mx-4 whitespace-nowrap bg-blue-400 dark:bg-blue-500 cursor-not-allowed font-medium rounded-lg text-sm px-5 py-1 h-12 text-center
}
.fw-sky-btn{
  @apply text-white max-md:mx-10 max-md:right-0 max-md:px-2 max-md:py-2 right-14 mt-3 mx-0  bg-sky-400 dark:bg-sky-500  font-medium rounded-lg text-sm px-5 py-2.5 h-10 text-center
}

.ant-tree-node-content-wrapper{
  line-height: 30px !important;
}

.ant-tree-node-content-wrapper-open{
  line-height: 21px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*=======================================Max Media Query========================================================= */

@media (max-width: 640px) {


}

@media (max-width: 768px) {

  .uppy-Dashboard-inner {
    height: auto!important;
  }

  .upload-logo {
    width: 50px;
    height: 50px;
  }

  .ant-layout-content {
    padding: 5px!important;
  }

  .first-flex-div {
    order: 1;
    padding: 20px;
  }

  .second-flex-div {
    order: 2;
    display: block;
  } 
}

@media (max-width: 1024px) {

}

@media (max-width: 1280px) {
  .uppy-Container {
    max-width: 400px;
    height: 100%;
  }
}

@media (max-width: 1536px) {
  
}

/*=======================================Min Media Query========================================================= */

@media (min-width: 640px) {


}

@media (min-width: 768px) {

}
@media (min-width: 1024px) {

}
@media (min-width: 1280px) {

}
@media (min-width: 1536px) {

}
