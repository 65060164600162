@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.upload-logo {
  width: 150px;
  height: 150px;
}

.isSelectedImg{
  background-color: lightgray;
  border: solid 3px lightgray;
  border-radius: 4px;
}

.bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity))!important;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-pagination .ant-pagination-prev, :where(.css-dev-only-do-not-override-14wwjjs).ant-pagination .ant-pagination-next, :where(.css-dev-only-do-not-override-14wwjjs).ant-pagination .ant-pagination-jump-prev, :where(.css-dev-only-do-not-override-14wwjjs).ant-pagination .ant-pagination-jump-next{
  min-width: 15px;
}

.gallary-text-lg {
  font-size: 18px !important;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle, :where(.css-dev-only-do-not-override-14wwjjs).ant-tree .ant-tree-checkbox+span .ant-tree-iconEle{
  height: 36px;
}